.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  padding: 0 250px;
  box-sizing: border-box;
  background: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  .logo{
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: space-between;
    cursor: pointer;
    span{
      padding: 4px;
      border-radius: 4px;
      opacity: 1;
      box-sizing: border-box;
      border: 0.5px solid #222222;
      font-weight: normal;
    }
  }
  .nav {
    cursor: pointer;
    font-weight: normal;
  }
}
.main {
  width: 100%;
  margin: 0 auto;
  font-family: Source Han Sans;
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  letter-spacing: 0em;

  .part1 {
    width: 100%;
    margin: 0 auto;
    height: 733px;
    background: linear-gradient(180deg, #fffce7 0%, #ffffff 100%);
    .titlecontent {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: center;
      padding-top: 64px;
      .title {
        font-family: Source Han Sans;
        font-size: 56px;
        font-weight: bold;
        line-height: 60px;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        background: linear-gradient(180deg, #ce2c0c 0%, #692901 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    .desc {
      height: 28px;
      margin-top: 24px;
      text-align: center;
      font-family: Source Han Sans;
      font-size: 24px;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #9b5d3f;
    }
    .img1 {
      margin-top: 23px;
      text-align: center;
    }
    .descborder {
      margin: 0 auto;
      width: 617px;
      height: 35px;
      border-radius: 164px;
      opacity: 1;
      line-height: 35px;
      text-align: center;
      background: #fff2e5;
      font-size: 14px;
      font-weight: normal;
      font-feature-settings: "kern" on;
      color: #a88a8a;
    }
    .optlist {
      margin: 0 auto;
      margin-top: 23px;
      width: 897px;
      height: 190px;
      border-radius: 10px;
      opacity: 1;

      background: #ffffff;

      box-shadow: 0px 11px 15px 0px rgba(255, 180, 118, 0.2);
      display: flex;
      justify-content: space-around;
      align-items: center;
      .opt {
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        font-family: Source Han Sans;
        font-size: 20px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 0em;
        cursor: pointer;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        color: #757575;
      }
    }
  }

  .part2 {
    width: 1400px;
    margin: 0 auto;
    .section {
      margin: 0 auto;
      margin-top: 21px;
      padding:0 20px;
      box-sizing: border-box;
      width: 1000px;
      height: 412px;
      border-radius: 10px;
      opacity: 1;
      display: flex;
      background: #ffffff;
      gap: 20px;
      .left {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .right {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .word {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        > div {
          margin: 15px 0;
        }
        .card{
          width: 116px;
          height: 42px;
          border-radius: 8px;
          opacity: 1;
          color: #fff;
          background: #FF8A23;
          text-align: center;
          line-height: 42px;
        }
        .title {
          font-size: 36px;
          font-weight: bold;
          line-height: 40px;
          color: #222222;
        }
        .desc {
          font-size: 18px;
          font-weight: normal;
          line-height: 30px;
          color: #757575;
        }
      }
      .secimg {
        width: 100%;
      }
    }
  }

  .part3 {
    width: 100%;
    margin: 0 auto;
    margin-top: 21px;
    height: 430px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );

    .titlecontent {
      display: flex;
      height: 80px;
      align-items: center;
      justify-content: center;
      .title {
        font-family: Source Han Sans;
        font-size: 56px;
        font-weight: bold;
        line-height: 60px;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
        background: linear-gradient(180deg, #ce2c0c 0%, #692901 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
    .desc {
      margin-top: 24px;
      text-align: center;
      font-size: 24px;
      font-weight: normal;
      line-height: 49px;
      color: #9b5d3f;
      width: 511px;
      height: 49px;
      border-radius: 304px;
      opacity: 1;
      box-sizing: border-box;
      border: 0.5px solid #000000;
    }
    .descborder {
      margin: 0 auto;
      width: 617px;
      height: 35px;
      border-radius: 164px;
      opacity: 1;
      line-height: 35px;
      text-align: center;
      background: #fff2e5;
      font-size: 14px;
      font-weight: normal;
      font-feature-settings: "kern" on;
      color: #a88a8a;
    }
  }

  .bigBtn {
    margin: 23px auto;
    width: 404px;
    height: 100px;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    background: linear-gradient(90deg, #ff9c33 0%, #ff3d3d 100%);

    box-sizing: border-box;
    border: 1px solid #fff199;

    box-shadow: 0px 8px 12px 0px rgba(255, 101, 39, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title1 {
      opacity: 1;

      font-family: Source Han Sans;
      font-size: 34px;
      font-weight: 900;
      line-height: 38px;
      letter-spacing: 0em;

      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      background: linear-gradient(180deg, #ffffff 0%, #fcffb9 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .title2 {
      font-size: 16px;
      font-weight: 350;
      line-height: 22px;
      color: #ffffff;
    }
  }

  .smallBtn {
    margin: 23px auto;
    width: 404px;
    height: 80px;
    border-radius: 20px;
    opacity: 1;
    cursor: pointer;
    background: linear-gradient(90deg, #ff9c33 0%, #ff3d3d 100%);

    box-sizing: border-box;
    border: 1px solid #fff199;

    box-shadow: 0px 8px 12px 0px rgba(196, 196, 196, 0.3);

    .downtitle {
      text-align: center;
      font-size: 34px;
      font-weight: 900;
      line-height: 80px;
      background: linear-gradient(180deg, #ffffff 0%, #fcffb9 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.footer {
  width: 100%;
  margin: 0 auto;
  height: 307px;
  opacity: 1;
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0em;
  box-sizing: border-box;
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  color: #9e9e9e;
  background: #2c2c2c;
  padding: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  > div {
    margin-bottom: 39px;
    text-align: center;
  }
  .description {
    width: 452px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
    }
  }
  .record {
    margin-bottom: 0;
    /* 去掉所有 a 标签的下划线 */
  }
}

